@import "main";

//Top Nav

.ant-layout-sider .ant-layout-sider-children ul.ant-menu li.ant-menu-item,
.ant-layout-sider .ant-layout-sider-children ul.ant-menu li.ant-menu-submenu>div.ant-menu-submenu-title {
	padding-left: 12px !important;
}

.ant-layout-sider .ant-layout-sider-children ul.ant-menu li.ant-menu-submenu ul.ant-menu.ant-menu-sub li.ant-menu-item {
	padding-left: 30px !important;
	padding-inline: 12px;
}

.ant-menu svg {
	height: fit-content;
	width: 20px;
	fill: white;
	stroke: white;
}

.tabs svg {
	width: 20px;
	height: fit-content;
}

.tabs-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	position: sticky;
	z-index: 998;
	top: 50px;
	margin: 0px -20px 15px;
	height: 52px;
}


.tabs {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	gap: 20px;
	background: white;
	height: auto;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	// height: 100%;
	align-items: center;
}

.tabs>div {
	padding-right: 20px;
}

.tabs>div:first-child {
	padding-left: 20px;
}

.tabs::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.selectedKey {
	border-bottom: 1px solid var(--primary) !important;
	color: var(--primary) !important;
	font-weight: 500;

}


//Preview 

@media screen {
	@page {
		padding-top: 20mm;
	}

	.contact-details {
		margin-top: 30px;

	}
}

.add-receipt-icon {
	display: none;
}

.ant-modal .ant-modal-content {
	padding: 0px !important;
}

.add-receipt-header {
	box-shadow: 0px 3px 6px #00000029 !important;
	border-radius: 0px 0px 40px 40px;
	background-color: var(--faded-grey);
	padding: 20px 20px;
	margin: 0 0 5px;
	height: 100%;
	width: 100%;
}

.receipt-total {
	padding: 6px 36px;
	color: white;
	background-color: var(--primary);
	border-radius: 10px 0 0 10px !important;
	font-weight: bold;
	border: 1px solid var(--primary);
}

.sum {
	padding: 6px 36px;
	border-radius: 0 10px 10px 0 !important;
	border-left: none;
	background-color: var(--faded-grey);
	border: 1px solid var(--primary);
}

.contact-details-inner {
	gap: 5px;
	display: flex;
	flex-direction: column;
}

.contact-details {
	word-break: break-all;
	color: #545454;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
}

.top-50 {
	margin-top: 50px;
}

.invoice-table {
	margin-top: 20px;
}

.invoice-table.ant-table-wrapper .ant-table-tbody>tr>td {
	border-bottom: 1px solid black;
}

.add {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	width: 100%;
	cursor: pointer;
	gap: 10px;
	color: var(--darker-grey);
	background-color: white;
	border-radius: 10px;
	padding: 5px;
	border: 1.5px solid var(--border)
}

.flex {
	align-items: center;
	min-height: 35px;
	display: flex;
}

.selectedSort {
	border: 1px var(--primary) solid;
}

.sortBy {
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
}

.sortBy>div {
	cursor: pointer;
	white-space: nowrap;
	background-color: #00000017;
	color: #5B5B5B;
	border-radius: 12px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	padding: 10px;
}

.p-5 .ant-col {
	padding-top: 10px;
}

.header {
	font-family: Segoe UI;
	font-weight: bold;
	font-size: 22px;
}

.gray {
	color: "#545454"
}

.header-gray {
	margin-bottom: 31px !important;
	font-family: Segoe UI;
	font-weight: bold;
	font-size: 22px;
	color: var(--darker-grey);
}

.sub-header {
	color: var(--darker-grey);
	font-family: Segoe UI;
	font-weight: bold;
	font-size: 17px;
}

.inner {
	font-size: 15px;
}

.top {
	margin-top: 47px;
}

.top-10 {
	margin-top: 10px;
}

.top-20 {
	margin-top: 20px;
}

.bottom-10 {
	margin-bottom: 10px;
}

.top-15 {
	margin-top: 15px;
}

.bottom {
	margin-bottom: 20px;
}

.active {
	width: 100%;
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.active .ant-form-item {
	margin-bottom: 5px;
}

.preview .ant-form-item {
	margin-bottom: 0px;
}

.preview .upload .ant-upload-drag-icon {
	height: 40px;
	font-size: 30px;
	margin: 0;
	padding: 0;
	margin-right: 5px;
	display: flex;
	align-items: center;


}

.preview .upload .ant-upload {
	height: 40px !important;
	width: 150px !important;
}


.preview .upload .ant-upload-text {
	color: white;
}

.preview .ant-upload-picture-card-wrapper {
	height: 50px !important;
	width: 150px;

}

.preview .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select>.ant-upload {
	height: 50px;
	padding: 5px;
	background-color: #545454;
	border-radius: 10px;
}

.preview.blue .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select>.ant-upload {

	background-color: var(--primary);
}

.preview .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
	border: none;
}

.preview .ant-upload.ant-upload-select-picture-card .ant-upload-text {
	color: white;
	font-size: 16px;
}


.preview .ant-upload.ant-upload-select-picture-card>.ant-upload {
	justify-content: flex-start;
}

.remove-logo {
	width: 150px;
	height: 40px;
	gap: 10px;
	color: var(--darker-grey);
	background-color: white;
	border-radius: 10px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1.5px solid var(--darker-grey);
	cursor: pointer;
}

.remove-logo.blue {
	border-color: var(--primary);
	color: var(--primary);
}

.color-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.remove-logo:nth-child(even) {
	margin-top: 10px;

}

.ant-progress-line {
	margin-bottom: 0px !important;
}

.green.ant-progress .ant-progress-bg {
	background-color: var(--green);
}

.red.ant-progress .ant-progress-bg {
	background-color: red;
}

.extra-icons {
	z-index: 997;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	border: 1px solid var(--primary);
	box-shadow: 0px 3px 6px #00000029 !important;
	bottom: -15px;
	transform: translate(-50%, 0%);
	height: 30px;
	min-width: 30px;
	left: 50%;
	border-radius: 10px;
	padding: 2px 5px;
}

.two-line-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* Limit the text to two lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.separator {
	width: 2px;
	height: 20px;
	background-color: #ccc;
	margin: 0 5px;
	padding: 0px;
}

.userIcon {
	background-color: var(--primary);
	border-radius: 50%;
	padding: 5px;
	color: white;
	font-weight: 500;
	text-align: center;
	width: 40px;
}

.ant-slider .ant-slider-handle::after,
.ant-slider .ant-slider-handle.ant-tooltip-open::after,
.ant-slider .ant-slider-handle:focus::after,
.ant-slider .ant-slider-handle.ant-tooltip-open:focus::after {
	box-shadow: 0 0 0 2px var(--primary) !important;
	width: 15px;
	height: 15px;
	margin-bottom: 10px;
	top: -2px;
}

.add-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	gap: 5px
}

.level1 {
	position: sticky;
	top: 50px;
	z-index: 997;
	background: white
}

.level2 {
	position: sticky;
	top: 96px;
	z-index: 997;
	background: white
}

.level3 {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	position: sticky;
	top: 130px;
	z-index: 997;
	padding-top: 10px;
	background: white
}


.level3.group {
	position: inherit;
	top: unset;
	z-index: inherit
}

input:focus {
	font-size: 16px;
}

div.gm-style-mtc button {

	padding: 0 5px !important;
}

.Toastify__toast-container {
	z-index: 11000;
}

.body {
	background: #EDEDED 0% 0% no-repeat padding-box;
}

// .logo .img-logo {
// 	max-width: 85px;
// 	margin: 2px 0 2px 52px;
// }

#scroll-left,
#scroll-right {
	width: 30px;
	display: flex;
	color: var(--primary);
	border: 1px solid var(--primary);
	align-items: center;
	justify-content: center;
	height: 42px;
	background-color: white;
	border-radius: 5px;
	cursor: pointer;
}

#menu-scroll-left,
#menu-scroll-right {
	width: 30px;
	display: flex;
	color: var(--light-grey);
	align-items: center;
	justify-content: center;
	height: 52px;
	background-color: white;
	cursor: pointer;
}

.ant-col.ant-form-item-label {
	display: block;
	width: 100%;
	text-align: left;
}

.mb-1 {
	margin-bottom: 1rem;
	display: inline-block;
}

.login-img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;

}

.login-form-wrapper {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-container {
	height: calc(100vh - 10px);
	display: flex;
	flex-direction: row;
	position: relative;
}

.forgetPass {
	font-size: 11px;
	color: #005DB4;
	text-decoration: underline;
	cursor: pointer;
	width: calc(100% - 100px);

}

.center {
	text-align: center;
}

.left-background {
	flex: 4;
	display: flex;
	background-color: white;
	justify-content: center;
	align-items: flex-end;

}

.right-background {
	flex: 3;
	background: linear-gradient(151deg, #005DB4, #5487B7, #283484, #005DB4);
}

.central-content {
	position: absolute;
	right: 0;
	margin-left: 50%;
	left: 0;
	bottom: 10%;
	height: 85%;
	width: 350px;
	background-color: #FAFAFA;
	box-shadow: 0px 10px 20px #00000029;
	border-radius: 25px;

}

#LoginForm {
	width: 100%;
	max-width: 350px;
	padding: 60px 50px 20px 50px;
	min-height: 100%;
	position: relative;
	// display: flex;
	// flex-direction: column;

}

#LoginForm .FormTitle {
	text-align: center;
	color: #005DB4;
	font-weight: bold;
	margin-bottom: 40px;
}

header.ant-layout-header {
	margin-top: -10px;
	padding-right: 15px !important;
	background-color: #f0f2f5 !important;
	align-items: center;
	justify-content: flex-end;
	display: flex;
	min-height: 50px;
	max-height: 50px;
}

header.ant-layout-header.white {
	background-color: white !important;
}

.ant-layout {
	background-color: #f0f2f5 !important;
}

.ant-layout .site-layout {
	background-color: #f0f2f5;
}

.right-part {
	min-height: 50px;
	max-height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 30px
}

#notification-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	width: 32px;
	height: 30px;
	background-color: #001529;
	padding: 5px;
	border-radius: 5px;


	cursor: pointer;
}

.ant-drawer-header {
	background-color: #001529;
}

#logout {
	cursor: pointer;
	transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
		color 0.3s;
	font-size: 14px;
	color: white !important;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	background-color: #001529;
	// width: 118px;
	height: 30px;
	border-radius: 5px;
}

#user-icon {
	width: 30px;
}


p.error {
	color: #f44336;
	font-size: 14px;
	margin: 10px 0;
	text-align: center;
}

.anticon-delete {
	color: #f44336;
}

.anticon-more-phones,
.anticon-edit {
	color: #1890ff;
}

.width-auto {
	width: auto !important;
}

.align-right {
	text-align: right;
}

aside#fileDetails {
	ul.selectedFiles {
		list-style-type: none;
		padding: 0;
		margin: 7px 0;

		li {
			font-size: 14px;
			color: #4caf50;
			margin: 0;

			h4 {
				font-size: 14px;
				color: #4caf50;
				margin: 0 5px 0 0;
				display: inline-block;
			}

			.btn-custom {
				margin: 1rem 0;
				width: auto;
			}
		}
	}
}

.download-sample-file {
	text-align: right;
	margin-bottom: 5px;

	svg {
		margin-right: 5px;
	}
}

.btn-wrapper-right {
	text-align: right;
}

#translationFilters {
	display: flex;
	flex-wrap: wrap;

	.field-container {
		margin-right: 15px;
	}

	.field-container:last-child {
		margin-right: 0px;
	}
}

.btn-custom {
	background-color: var(--primary) !important;
	display: block;
	color: #fff !important;
}

p.align-left {
	text-align: left;
}

h2.title {
	margin-bottom: 1.5rem;
}

#translationFilters {
	.ant-select {
		min-width: 200px;
	}
}

.ant-collapse-header {
	align-items: center !important;

	.ant-form-item-explain {
		display: none;
	}

	.main-checkbox {
		min-height: 23px;
	}
}

.permissions-container {
	display: flex;
	flex-wrap: wrap;

	.ant-form-item-explain {
		display: none;
	}

	.main-checkbox {
		min-height: 23px;
	}
}

.page-404 {
	display: flex;
	flex-wrap: wrap;
	height: 100vh;
	align-items: center;
	justify-content: center;

	.wrapper-404 {
		text-align: center;

		h1 {
			font-size: 6rem;
			font-weight: 600;
			line-height: 1;
			margin: 0;
			width: 100%;
		}

		h2 {
			font-size: 2rem;
			line-height: 1;
			width: 100%;
		}
	}
}

.page-session-ended {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.wrapper-se {
		text-align: center;
		margin-top: 20%;

		h2 {
			font-size: 1.5rem;
			line-height: 1;
		}

		button {
			width: auto;
		}
	}
}

.page-forbidden {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.content-wrapper {
		text-align: center;
		margin-top: 13%;

		h1 {
			font-size: 4rem;
			line-height: 1.3;
			margin: 0;
		}

		h2 {
			font-size: 1.5rem;
			line-height: 1;
		}

		button {
			width: auto;
		}
	}
}

.inner-loader-wrapper {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;

	.inner-loader-text {
		color: black;
		margin: 10px 0;
		font-size: 20px;
	}
}

.loader-wrapper {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);

	.loader-text {
		color: #fff;
		margin: 10px 0;
		font-size: 20px;
	}
}



/* LoadingIndicator.css */
.loading-indicator-container {
	position: fixed;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
}

.loading-indicator {
	width: 24px;
	height: 24px;
	border: 3px solid #3498db;
	border-top: 3px solid transparent;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}


.loading-line {
	width: 100%;
	height: 5px;
	background-color: #3498db;
	/* Adjust color as needed */
	animation: loading-animation 2s linear infinite;
}

@keyframes loading-animation {
	0% {
		width: 0;
	}

	50% {
		width: 50%;
	}

	100% {
		width: 100%;
	}
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.first-row-loading {
	position: sticky;
	width: 100%;
	top: 165px;
	background: white;
	z-index: 1000;
}



.btn-disabled:before {
	display: block;
}

.height-25 {
	height: 25px;
}

.ant-select-tree-list {
	border: 1px solid #40a9ff;
}

.ant-select-tree-treenode.ant-select-tree-treenode-checkbox-checked .ant-select-tree-node-content-wrapper {
	background-color: #bae7ff;
}

.ant-select-tree-switcher-noop {
	display: none;
}

.app {
	margin-bottom: 1.5rem;

	.color_picker {
		width: 100%;
		height: 200px;
		padding: 15px 5px;
		border: 1px solid #ccc;
		border-radius: 10px;
	}

	.hue_slider {
		width: 400px;
	}

	.hue_slider_vertical {
		height: 200px;
	}

	.saturation_value {
		width: 400px;
		height: 400px;
	}
}

.color-box {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #aeb6b5;
}

.ReactColorPicker-module_react_color_picker__1OEfS .ReactColorPicker-module_details__106p_ .ReactColorPicker-module_hex_value__10kKY {
	width: 80px !important;
}


.ant-layout-sider {
	z-index: 1000;
	position: fixed !important;
	top: 10px !important;
	margin-left: 10px !important;
	right: auto;
	bottom: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	border-radius: 10px;

	.ant-layout-sider-children {
		margin-top: 10px;
		height: auto;


		.logo {
			padding-left: 12px;
			z-index: 999;
		}
	}
}

.ant-drawer-title .logo {
	padding-left: 24px;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed)+.ant-layout.site-layout>main.ant-layout-content {
	margin-left: 270px !important;
}


.ant-select.main-select-input.multiselect {
	min-height: 40px;
}

.ant-select-selection-overflow {
	margin-top: 7px;
}

.table-search-wrapper .ant-select-selection-overflow {
	margin-top: 0;
}

.ant-form-item-with-help .ant-form-item-explain {
	margin-bottom: 7px;
}

.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-halfSize {
	width: 660px !important;
	left: 0;
	right: 0;
	margin: 0 auto;
}

/**************scroll bar start******************/
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/**************scroll bar end******************/
.sticky-top-nav {
	position: sticky;
	width: 100%;
	top: 0;
	right: 0;
	bottom: auto;
	left: 0;
	z-index: 998;
}

.sticky-header {
	position: sticky;
	width: 100%;
	top: 65px;
	right: 0;
	bottom: auto;
	left: 0;
	z-index: 999;
	margin: 0 0 20px !important;
}

.proceed {
	height: 100px;
	border-radius: 10px;
	box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.1) !important;
	padding: 10px;
	position: sticky;
	bottom: 0px;
	z-index: 999;
	background: white;
	margin-top: -10px;
	display: flex;
	align-items: center;
	justify-content: center;
	// position: fixed;
	// left: 0;
	// right: 0;
	// bottom: 0
}

.sticky-footer {
	position: sticky;
	bottom: 0;
	z-index: 999;
	margin-bottom: 20px;
	padding: 10px;
}

.form-btns-wrapper {
	display: flex;
	justify-content: right;
	gap: 20px;
	// padding: 20px 30px !important;
	padding: 10px;
	border-radius: 14px;
	box-shadow: 5px 5px 10px 5px #00000029 !important;
	max-width: 100%;
	background: #ffffff33;
}

.form-btns-wrapper>.main-button {
	width: 80px !important;
	margin-right: 10px;
}

.form-btns-wrapper>.main-button.large {
	width: 100px !important;

}

// .add-new-form .main-button {
// 	width: 150px !important;
// }
// .add-new-form .section-wrapper{
// 	background-color: var(--primary);
// }
.section-wrapper {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 8px 8px 6px #00000029;
	border-radius: 14px;
	opacity: 1;
	padding: 20px 20px;
	margin: 0 0 5px;
	height: 100%;
	// max-width: 100%;
}


@media (max-width: 767px) {
	.tabs-container {
		margin: 0px -10px 15px;
	}

	.add-receipt-icon {
		display: block;
	}

	.central-content {
		width: 300px;
	}

	.add-row {
		justify-content: center;
	}

	//RANGE PICKER

	.ant-picker-dropdown .ant-picker-range-wrapper {
		justify-content: center;
		overflow: auto;
	}

	.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
		flex-direction: column;
	}

	.ant-picker-dropdown .ant-picker-panel-container {
		box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.4), 0 3px 6px -4px rgba(0, 0, 0, 0.2), 0 9px 28px 8px rgba(0, 0, 0, 0.2) !important
	}

	.right-part {
		gap: 10px;
	}

	#logout {
		min-width: 80px;
		gap: 0px
	}

	.ant-layout-footer {
		padding: 2px 50px;
	}

	.ant-table-tbody>tr>td,
	.ant-table-thead>tr>th,
	.ant-table tfoot>tr>td,
	.ant-table tfoot>tr>th {
		overflow-wrap: break-word;
	}

	ul.user-role-permissions li.single-row,
	ul.user-role-permissions li.single-row .permission-label,
	ul.user-role-permissions li.single-row .field-container {
		width: 100%;
	}

	.btn-wrapper-right {
		text-align: left;
	}

	.page-session-ended .wrapper-se h2 {
		font-size: 1.3rem;
	}

	.permissions-container .field-container {
		width: 100%;
	}

	.page-forbidden .content-wrapper h1 {
		font-size: 2.5rem;
	}

	.title .ant-space {
		flex-wrap: wrap;
	}

	.section-inner {
		margin: 10px 0 !important;
	}

	main.ant-layout-content {
		margin: 10px !important;
	}

	.section-wrapper .inner-row button {
		margin-bottom: 15px;
	}

	.form-btns-wrapper {
		padding: 10px !important;
		justify-content: center;
	}

	.section-wrapper {
		padding: 5px 10px;
	}

	.section-wrapper.add-receipt {
		padding: 20px !important;
	}

	.login-form-wrapper {
		height: 100vh;
	}

}

@media (max-width:450px) {

	#LoginForm {
		padding: 60px 20px 20px 20px;
		max-width: 300px;

	}

	.central-content {
		margin-left: auto;
		margin-right: auto;
	}

	.forgetPass {
		width: calc(100% - 40px);
	}


}



///////////////////////// ////////////////////////////////////
/// 
/// 

@media (min-width: 768px) {
	.tabs::before {
		content: "";
		margin-left: auto;
	}

	.tabs::after {
		content: "";
		margin-right: auto;
	}

	.section-inner {
		max-width: calc(50% - 20px) !important;
	}

	.level3 {
		top: 96px;
		position: sticky;
		background-color: white;
	}

	.level3.group {
		position: sticky;
		top: 96px;
		z-index: 997;
		background-color: white;
	}


	.level2 {
		top: 50px
	}
}

@media (min-width: 992px) {
	.section-wrapper-lg {
		max-width: calc(33.33333333% - 20px) !important;
	}

	.section-inner {
		max-width: calc(33.33333333% - 20px) !important;
	}

	.section-inner-lg {
		max-width: calc(66.66666667% - 20px) !important;
	}

	.section-inner-6 {
		max-width: calc(25% - 20px) !important;
	}

	.section-inner-12 {
		max-width: calc(50% - 20px) !important;
	}
}

.inner-row {
	justify-content: space-around;
}

.section-inner {
	margin: 10px;
}

.form-tag {
	display: block;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 5px;
}

.customer-location {
	width: 100%;
	height: 100%;
}

.panel-div {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 8px 8px 6px #00000029 !important;
	border-radius: 14px;
}

.ant-upload.ant-upload-select-picture-card {
	width: 200px;
	height: 100%;
	display: block;
	margin: auto;
}

.active-label {
	padding-right: 10px;
}

.flex-col {
	display: flex !important;
}

.flex-col-start {
	justify-content: flex-start;
}

.flex-col-end {
	justify-content: flex-end;
}

.flex-col-center {
	justify-content: center;
}

.action-btns {
	width: 20px !important;
	height: 20px !important;
}

.branch.ant-collapse,
.checklist.ant-collapse {
	border: none;
	background-color: white;

}

.checklist.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
	padding: 16px 0px;
}

.branch.ant-collapse>.ant-collapse-item:last-child,
.branch.ant-collapse>.ant-collapse-item {
	border: none;
	margin-bottom: 20px;
	margin-bottom: 16px;
	background-color: #fafafa;
	border-radius: 20px;
}

.checklist.ant-collapse>.ant-collapse-item:last-child,
.checklist.ant-collapse>.ant-collapse-item {

	border: none;
	margin-bottom: 20px;
	margin-bottom: 16px;
	background-color: white;
	border-radius: 20px;
	box-shadow: 2px 2px 2px 2px #00000029 !important;

}

.branch.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-content,
.branch.ant-collapse>.ant-collapse-item>.ant-collapse-content {

	border: none;
	background-color: #fafafa;
	border-radius: 20px;
}

.checklist.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-content,
.checklist.ant-collapse>.ant-collapse-item>.ant-collapse-content {

	border: none;
	background-color: white;
	border-radius: 20px;
}

.branch .ant-collapse-header,
.checklist .ant-collapse-header {
	font-size: 16px;
	font-weight: bold;
}

.branch .ant-form-item-with-help .ant-form-item-explain,
.checklist .ant-form-item-with-help .ant-form-item-explain {
	min-height: unset !important;
}

.branch .ant-form-item-with-help .ant-form-item-explain,
.checklist .ant-form-item-with-help .ant-form-item-explain {
	margin-bottom: 0;
}

.horizontal .ant-form-item-row {
	display: flex;
	flex-direction: row;
}

.horizontal .ant-form-item-control {
	max-width: fit-content;
}

.horizontal.space-between .ant-form-item-row {
	justify-content: space-between;
}

.horizontal .ant-form-item-label {
	width: unset;
}


.ant-modal-wrap {
	z-index: 1001 !important;
}

.ant-popover {
	z-index: 10100;
}

.upload .ant-upload {
	width: 100% !important;
	height: 200px !important;
}

.upload .ant-upload-drag-icon {

	font-size: 80px;
	margin: 0;
	padding: 0;

}

.upload .ant-upload-text {

	font-size: 14px;
	margin: 0;
	padding: 0;

}

.grid-container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	margin-top: 10px;
}

@media print {
	.preview {
		height: auto !important;
		padding: 10px;
	}

	.header {
		display: none;
	}

	.ant-table-wrapper.invoice-table .ant-table-sticky-scroll {
		display: none;
	}

	.invoice-table.ant-table-wrapper .ant-table-tbody>tr>td {
		border-bottom: 1px solid black;
	}

	.print-empty-col {
		width: 70%;
	}

	.print-col {
		width: 30%;
	}

	.a4 {
		width: 8.27in;
	}

	.letter {
		width: 8.5in;
	}


	// @page {
	//     @top-center { content: "hiiii" }
	// }
	// @page { 
	//     @bottom-center { 
	// 		content: element(footer) 
	// 	}
	// }
	// #footer {
	// 	position: sticky; 
	// 	bottom: 10px; 
	// }

	.received-by {
		page-break-before: avoid;
	}

	.contact-details {
		page-break-before: avoid;
	}
}

// #footer {

//     position: running(footer);
// 	page-break-before:avoid; 
// }

.invoice-table.ant-table-wrapper .ant-table-tbody>tr>td {
	border-bottom: 1px solid black;
}

.cards-view-wrapper {
	margin-top: 20px;
	padding-bottom: 30px;

}

@media (min-width: 1024px) {

	.cards-view-wrapper.ant-row {
		overflow-y: auto;
		max-height: 592.75px;
	}

}