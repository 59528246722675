@font-face {
	font-family: "regular";
	src: url(../font/Nunito-Regular.ttf);
}
@font-face {
	font-family: "bold";
	src: url(../font/Nunito-Bold.ttf);
}

:root {
	--default: #d9d9d9;
	--primary: #005DB4;
	--primary-hover: #71ade6;
	--secondary: #2c2c2c;
	--white: #fff;
	--disabled: #858585;
	--pink: #f06a80;
	--pink-hover: #f18193;
	--danger: #f44336;
	--dark: #676767;
	--red: #d95e70;
	--border: #a7a7a7;
	--purple: #dc6de8;
	--orange: #fac360;
	--yellow: #e4b80a;
	--dark-orange: #ee7f1d;
	--cyan: #1fa79d;
	--grey: #e6e6e6;
	--dark-grey: #cac6c6;
	--faded-grey:#F6F8FB;
	--darker-grey: #545454;
	--light-grey:#6B778C;
	--light-blue: #e1f7fa;
	--medium-blue: #6d8acc;
	--dark-blue: #283484;
	--green:#37C321;
	--green-hover:#1d5314;

	--baby-blue:#2876BC;

	--bold: "bold";
	--regular: "regular";
	--light: 200;

	--px-50: 50px;
	--px-40: 40px;
	--px-30: 30px;
	--px-25: 25px;
	--px-20: 20px;
	--px-18: 18px;
	--px-16: 16px;
	--px-14: 14px;
	--px-12: 12px;
	--px-10: 10px;

	--shadow: 5px 5px 5px rgba(0, 0, 0, 0.3) !important;
}

* {
	box-sizing: border-box !important;
	box-shadow: none !important;
	scroll-behavior: auto;
}
body {
	font-family: "regular" !important;
	font-size: var(--px-16) !important;
	letter-spacing: 1px;
	background-color: var(--bg-blue) !important;
	color: var(--secondary) !important;
}

/* FONT WEIGHTS */
.font-bold {
	font-family: var(--bold) !important;
}
.font-regular {
	font-family: var(--regular) !important;
}
.font-light {
	font-family: var(--light) !important;
}

/* FONT SIZES */
.font-50 {
	font-size: var(--px-50) !important;
}
.font-30 {
	font-size: var(--px-30) !important;
}
.font-20 {
	font-size: var(--px-20) !important;
}
.font-18 {
	font-size: var(--px-18) !important;
}
.font-16 {
	font-size: var(--px-16) !important;
}
.font-14 {
	font-size: var(--px-14) !important;
}
.font-12 {
	font-size: var(--px-12) !important;
}
.font-10 {
	font-size: var(--px-10) !important;
}
@media (max-width: 1000px) {
	.font-50 {
		font-size: 30px !important;
	}
	.font-30 {
		font-size: 25px !important;
	}
	.font-20 {
		font-size: 18px !important;
	}
	.font-18 {
		font-size: 16px !important;
	}
	.font-14 {
		font-size: 12px !important;
	}
	.font-12 {
		font-size: 10px !important;
	}
	.font-10 {
		font-size: 8px !important;
	}
}

@media (max-width: 400px) {
	/* .font-30 {
		font-size: 12px !important;
	} */
}

/* GUTTERS */
.no-padding {
	padding: 0 !important;
}
.no-margin {
	margin: 0 !important;
}

/* COLORS */
.primary-b {
	color: var(--primary) !important;
}
.secondary-b {
	color: var(--secondary) !important;
}
.danger-red {
	color: var(--danger) !important;
}
.white {
	color: var(--white) !important;
}
.dark {
	color: var(--dark) !important;
}
.grey {
	color: var(--grey);
}
.dark-grey {
	color: var(--dark-grey);
}
.purple {
	color: var(--purple);
}
.dark-blue {
	color: var(--dark-blue);
}

/* TYPOGRAPHY */
.uppercase {
	text-transform: uppercase;
}

.pointer {
	cursor: pointer;
}

/* ROW */
.main-row {
	height: 100%;
}
@media (max-height: 750px) {
	.main-row {
		height: auto;
	}
}

/* LINK */
.primary-link,
.primary-link:hover,
.primary-link:focus,
.primary-link:active {
	color: var(--primary);
	font-size: var(--px-14);
	text-decoration: underline;
	height: 100%;
}

.pages-container {
	transition: all 0.5s;
}
.pages-container.openMenu-onpages {
	position: relative;
	left: -90%;
}
.main-web {
	position: relative;
	transition: all 0.5s;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
}
.layout-container {
	position: relative;
}
/* CHECKBOX */
.main-checkbox {
	color: var(--primary);
	/* width: 40px; */
	min-height: 40px;
	align-items: center;
}


.main-checkbox > .ant-checkbox > .ant-checkbox-inner,
.main-checkbox > .ant-checkbox > .ant-checkbox-inner:hover,
.main-checkbox > .ant-checkbox > .ant-checkbox-inner:focus,
.main-checkbox > .ant-checkbox > .ant-checkbox-inner:active {
	border: 1px solid var(--primary) !important;
}
.main-checkbox > .ant-checkbox-checked > .ant-checkbox-inner,
.main-checkbox > .ant-checkbox-checked > .ant-checkbox-inner:hover,
.main-checkbox > .ant-checkbox-checked > .ant-checkbox-inner:focus,
.main-checkbox > .ant-checkbox-checked > .ant-checkbox-inner:active {
	background: var(--primary) !important;
	border: 1px solid var(--primary) !important;
}
.main-checkbox > .ant-checkbox-checked::after {
	border-color: var(--primary);
}

.message_shadow > .ant-message-notice-content {
	/* background-color: var(--white); */
	box-shadow: 0 4px 8px 0 var(--grey), 0 6px 20px 0 var(--grey) !important;
	/* border: 1px solid var(--grey); */
}
/* MAIN RADIO */
/* CHECK DONATE PAGE FOR IMPLEMENTATION */
.main-radio .green-radio {
	margin-bottom: 15px;
}
.main-radio .green-radio > .ant-radio .ant-radio-inner {
	border: 1px solid var(--border);
}
.main-radio .green-radio > .ant-radio.ant-radio-checked .ant-radio-inner {
	border-color: var(--primary);
}
.main-radio
	.green-radio
	> .ant-radio.ant-radio-checked
	.ant-radio-inner::after {
	background-color: var(--primary);
}

.ant-notification-notice {
	box-shadow: 0 4px 8px 0 var(--dark-grey), 0 6px 20px 0 var(--dark-grey) !important;
}

dbo {
	unicode-bidi: bidi-override;
}
