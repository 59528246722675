

#sider-open {
    display: none;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #001529;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: fixed;
    top: 10px;
    left: 20px;
    cursor: pointer;
    z-index: 999;
}
#menu-open {
 display: none;
}
#close-sider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #001529;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: fixed;
    top: 10px;
    left: 270px;
    cursor: pointer;
    z-index: 999;
}
.ant-drawer-body {
    background: #001529;
}
.ant-drawer-header-close-only {
    background-color: #001529 !important;
}

.anticon-bars {
    padding: 6px !important;
    z-index: 0 !important;
    position: unset !important;
}

a .ant-menu-item,.ant-menu-item a, .ant-menu-dark .ant-menu-inline.ant-menu-sub, .ant-menu-submenu-title{
    color: white !important;
    
    font-size: 16px;
   
}
a li.ant-menu-item{
    padding-left: 72px !important;
}
.ant-menu-submenu-title-open, .ant-menu-item-selected {
    color: #2699FB !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1890ff6b !important;
}
.ant-menu-item:hover,.ant-menu-item a:hover, .ant-menu-submenu-title:hover{
    color: #2fb3ff !important;
    border-radius: 5px;
    
}
.ant-menu-submenu-selected {
    background-color: #001529 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #001529 !important;
}
.ant-menu-vertical.ant-menu-sub:not([class*=-active]) {
   display: none !important;
}

.ant-drawer-header-close-only {
    border-radius: unset !important;
}

@media (max-width: 767px) {
    .ant-menu-item a, .ant-menu-dark .ant-menu-inline.ant-menu-sub, .ant-menu-submenu-title{
       font-size: 20px;
       
    }
	#menu-open {
        display: flex;
        align-items: center;
    justify-content: center;
        width: 30px;
        height: 30px;
        background-color: #001529;
        color: white;
        padding: 4px;
        border-radius: 5px;
        position: fixed;
        top: 10px;
        left: 20px;
        cursor: pointer;
        z-index: 999;
    }
    
    .ant-layout-sider-zero-width-trigger, #sider-open{
        display: none;
    }
    #close-sider {
        display: none;
    }
}

.close-drawer-icon {
    padding-left: 0px !important;
}