.main-button.ant-btn {
	display: block;
	height: 40px;
	border-radius: 15px;
	position: relative;
	font-size: var(--px-14);
	border-color: transparent;
	text-align: center;
	white-space: nowrap;
	line-height: 15px;
	background-color: var(--primary);
}

.main-button.ant-btn::after {
	content: none;
}
.main-button.ant-btn>img,
.main-button.ant-btn>i {
	height: 16px;
	margin-right: 5px;
}
.btn-download {
	width: auto !important;
}

.isPrimary,
.isPrimary:active {
	color: var(--white) !important;
	border: 1px solid transparent !important;
	background-color: var(--primary) ;
	width: auto ;
}


.isPrimary:focus{
	border: 1px solid var(--default) !important;
}
.isPrimary:not(.btn-disabled):hover {
	border-color: var(--primary-hover) !important;
	background-color: var(--primary-hover) !important;
}


.isPrimary.btn-small {
	height: 30px !important;
}

.isPrimary.btn-half-width {
	height: 30px !important;
	width: 50% !important;
}


.isGreen,
.isGreen:active {
	color: var(--white) !important;
	border: 1px solid transparent !important;
	background-color: var(--green) !important ;
	width: auto ;
}


.isGreen:focus{
	border: 1px solid var(--default) !important;
}
.isGreen:not(.btn-disabled):hover {
	border-color: var(--green-hover) !important;
	background-color: var(--green-hover) !important;
}


.isRed,
.isRed:active {
	color: var(--white) !important;
	border: 1px solid transparent !important;
	background-color: var(--danger) !important ;
	width: auto ;
}

.isRed:focus{
	border: 1px solid var(--default) !important;
}
.isRed:not(.btn-disabled):hover {
	border-color: var(--red) !important;
	background-color: var(--red) !important;
}





.isSecondary.black,
.isSecondary:focus.black,
.isSecondary:active.black

{
	background-color: black;
	color: white;
} 
.isSecondary.danger,
.isSecondary:focus.danger,
.isSecondary:active.danger

{
	background-color: var(--danger);
}
.isSecondary:not(.btn-disabled):hover.danger{
	background-color: rgb(230, 159, 159);
}

.isSecondary,
.isSecondary:focus,
.isSecondary:active{
	color: var(--white) !important;
	border: 1px solid transparent !important;
	background-color: var(--primary) ;
}
.isSecondary:not(.btn-disabled):not(.cancel):hover {
	background-color: var(--primary-hover) ;
}

.isWhite,
.isWhite:focus,
.isWhite:active,
.isWhite:hover {
	color: var(--secondary) !important;
	border: 1px solid transparent !important;
	background-color: var(--white) !important;
	box-shadow:0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.isWhite.red,
.isWhite.red:focus,
.isWhite.red:active,
.isWhite.red:hover {
	color:var(--danger) !important;
                            box-shadow:0 2px 4px rgba(0, 0, 0, 0.2) !important;
}


.isWhite.green,
.isWhite.green:focus,
.isWhite.green:active,
.isWhite.green:hover {
	color:var(--green) !important;
}


.isOutline,
.isOutline:focus,
.isOutline:active {
	border: 1px solid var(--primary) !important;
	color: var(--primary) !important;
	background-color: var(--white) !important;
}

.isOutline:hover {
	border: 1px solid var(--primary) !important;
	color: var(--white) !important;
	background-color: var(--primary) !important;
}

.isDefault,
.isDefault:focus,
.isDefault:active {
	border: 1px solid var(--default) !important;
	color: rgba(0, 0, 0, .85) !important;
	background-color: var(--white) !important;
	/* width: auto !important; */
}

.isDefault:hover {
	color: var(--primary-hover) !important;
	border-color: var(--primary-hover) !important;
}

.isInverted,
.isInverted:focus,
.isInverted:active,
.isInverted:hover {
	border: 1px solid var(--white) !important;
	color: var(--white) !important;
	background-color: var(--primary) !important;
}

.isWhite,
.isWhite:focus,
.isWhite:active,
.isWhite:hover {
	border: 1px solid var(--white) !important;
	color: var(--primary) !important;
	background-color: var(--white) !important;
}

.isGhost,
.isGhost:focus,
.isGhost:active,
.isGhost:hover {
	color: var(--white);
	border: 1px solid transparent;
	background-color: #bdc7de;
}

.isPrimary.btn-disabled,
.isSecondary.btn-disabled,
.isGhost.btn-disabled {
	background-color: var(--primary-hover);
}
.isWhite.btn-disabled{
	background-color: var(--gray) !important;
	color: var(--gray) !important;
}

.isOutline.btn-disabled {
	border-color: #d8d8d8;
	color: #d8d8d8;
}



.isPending,
.isPending:focus,
.isPending:active {
	color: #FF9B58 !important;
	font-weight: bold;
	border: 1px solid #FF9B58 !important;
	background-color: #FF9B5840 !important ;
	width: auto ;
}

.completed,
.completed:focus,
.completed:active {
	color: var(--primary) !important;
	font-weight: bold;
	background-color: rgb(219, 225, 238) !important ;
	width: auto ;
}


@media screen and (max-width: 768px) {
	.form-btns-wrapper{
		justify-content: flex-end;
	}
	.main-button.ant-btn.hidden{
		display: none;
	}
	
  }